import React from "react";
import classes from "../minter/minter.module.css";
import { ReactComponent as Collection } from "../../../assets/create/collection-icon.svg";
import { ReactComponent as Vibes } from "../../../assets/create/proofofvibes-icon.svg";
import { ReactComponent as Photo } from "../../../assets/create/photo-icon.svg";
import { ReactComponent as Sesh } from "../../../assets/create/sesh-icon.svg";
import { ReactComponent as Shorts } from "../../../assets/create/shorts-icon.svg";
import { ReactComponent as Art } from "../../../assets/create/art-icon.svg";
import { ReactComponent as Doubletake } from "../../../assets/create/bereal-icon.svg";
import { ReactComponent as Audio } from "../../../assets/create/MusicNotes.svg";
import { ReactComponent as Video } from "../../../assets/create/MintVideos.svg";
import { ReactComponent as Tweets } from "../../../assets/create/tweets-icon.svg";
import { ReactComponent as Ipfs } from "../../../assets/create/ipfsIcon.svg";

const cards = [
  {
    title: "Proof Of Vibes",
    description: "Mint your favorite moments on chain.",
    icon: <Vibes className={classes.vibeIcon} />,
    value: "Vibe",
    url: "/mint/vibe",
  },
  {
    title: "Proof Of Sesh",
    description: "Scan your stick for proof",
    icon: <Sesh className={classes.seshIcon} />,
    value: "Sesh",
    url: "/mint/sesh",
  },
];
export default cards;
