import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import MyNearIconUrl from "@near-wallet-selector/my-near-wallet/assets/my-near-wallet-icon.png";
import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";
import { setupWalletSelector } from "@near-wallet-selector/core";
import SenderIconUrl from "@near-wallet-selector/sender/assets/sender-icon.png";
import NearIconUrl from "@near-wallet-selector/near-wallet/assets/near-wallet-icon.png";
import { setupModal } from "@near-wallet-selector/modal-ui";

import "@near-wallet-selector/modal-ui/styles.css";
import { setupSender } from "@near-wallet-selector/sender";
import { setupNearWallet } from "@near-wallet-selector/near-wallet";
import { Magic } from "magic-sdk";
import { ConnectExtension } from "@magic-ext/connect";
import Web3 from "web3";
import * as WS from "./wallet-script";

import {
  setNotification,
  setProposedChain,
  setConnector,
  setChainId,
  setAccount,
  setMainnet,
  setClipboard,
  setToggleWalletPopup,
  setSwitchWalletNotification,
} from "../../gen-state/gen.actions";
import { chainIdToParams } from "../../utils/chainConnect";
import blankImage from "../../assets/blank.png";
import supportedChains from "../../utils/supportedChains";
import getConfig from "../wallet-popup/nearConfig";

export const breakAddress = (address = "", width = 6) => {
  if (address) return `${address.slice(0, width)}...${address.slice(-width)}`;
};

export const getConnectedChain = (chainId) => {
  const c = supportedChains[chainId];
  if (!c) return blankImage;
  return c.icon;
};

export const getNetworkID = () => {
  return new Promise(async (resolve) => {
    const networkId = await window.ethereum.request({ method: "net_version" });
    resolve(Number(networkId));
  });
};

export const initializeConnection = async (walletProps) => {
  const { dispatch, handleSetState, rpc, mainnet } = walletProps;
  const search = new URL(document.location).searchParams;

 if (
    window.localStorage.near_app_wallet_auth_key ||
    search.get("account_id") ||
    window.localStorage.getItem("near_wallet") === "connected_to_near"
  ) {
    const network = process.env.REACT_APP_ENV_STAGING === "true" ? "testnet" : "mainnet";
    const nearConfig = getConfig(network);
    const connectedToNearMainnet = {};
    if (process.env.REACT_APP_ENV_STAGING === "true") {
      connectedToNearMainnet.modules = [
        setupMyNearWallet({ walletUrl: "https://testnet.mynearwallet.com", iconUrl: MyNearIconUrl }),
        setupNearWallet({ iconUrl: NearIconUrl }),
      ];
    } else {
      connectedToNearMainnet.modules = [
        setupMyNearWallet({ walletUrl: "https://app.mynearwallet.com", iconUrl: MyNearIconUrl }),
        setupNearWallet({ iconUrl: NearIconUrl }),
        setupSender({ iconUrl: SenderIconUrl }),
      ];
    }
    const walletSelector = await setupWalletSelector({
      network: nearConfig,
      ...connectedToNearMainnet,
    });

    const isSignedIn = walletSelector.isSignedIn();
    window.selector = walletSelector;
    const connectedChain = process.env.REACT_APP_ENV_STAGING === "true" ? 1111 : 1112;
    if (isSignedIn) {
      window.localStorage.setItem("near_wallet", "connected_to_near");
      dispatch(setChainId(connectedChain));
      dispatch(setAccount(walletSelector.store.getState().accounts[0].accountId));
      dispatch(setProposedChain(connectedChain));
      dispatch(setConnector(walletSelector.wallet()));
      dispatch(
        setNotification({
          message: `Your site is connected to ${supportedChains[connectedChain].label}`,
          type: "success",
        })
      );
    }
  }
  // Subscribe to accounts change
};

export const setNetworkType = ({ dispatch, handleSetState }) => {
  dispatch(setMainnet(process.env.REACT_APP_ENV_STAGING === "false"));
  handleSetState({ network: process.env.REACT_APP_ENV_STAGING === "false" ? "mainnet" : "testnet" });
};

export const connectWithQRCode = async ({ walletConnectProvider, dispatch, supportedChains }) => {
  const proposedChain = Object.keys(walletConnectProvider.rpc)[0];
  try {
    await walletConnectProvider.enable();
    if (proposedChain !== String(walletConnectProvider.chainId)) {
      walletConnectProvider.disconnect();
      setTimeout(() => {
        alert(
          `Invalid connection! Please ensure that ${supportedChains[proposedChain].label} network is selected on your scanning wallet`
        );
        window.location.reload();
      }, 100);
    }
    dispatch(setConnector(walletConnectProvider));
  } catch (error) {
    console.log("error: ", error);
    dispatch(
      setNotification({
        message: "Connection failed",
        type: "error",
      })
    );
    dispatch(setProposedChain(null));
  }
};

export const connectWithMetamask = async (walletProps) => {
  const { dispatch, walletConnectProvider, supportedChains, proposedChain } = walletProps;
  let res;
  res = await supportedChains[proposedChain]?.switch(proposedChain);
  if (!res) {
    await WS.disconnectWalletConnectProvider(walletConnectProvider);
    const activeChain = await WS.getNetworkID();
    if (activeChain === proposedChain) {
      WS.updateAccount(walletProps);
    }
  } else if (res.message.includes("Unrecognized")) {
    res = await supportedChains[proposedChain].add(proposedChain);
    if (!res) {
      await WS.disconnectWalletConnectProvider(walletConnectProvider);
    } else {
      dispatch(
        setNotification({
          message: "Failed to add network",
          type: "error",
        })
      );
      dispatch(setProposedChain(null));
    }
  } else {
    dispatch(
      setNotification({
        message: "Connection failed",
        type: "error",
      })
    );
    dispatch(setProposedChain(null));
  }
};

export const initConnectWallet = async (walletProps) => {
  const {dispatch} = walletProps 
    // NEAR Connect
    const network =
    process.env.REACT_APP_ENV_STAGING === "true" ? "testnet" : "mainnet";
  const nearConfig = getConfig(`${network}`);
  const connectedToNearMainnet = {};
  if (process.env.REACT_APP_ENV_STAGING === "true") {
    connectedToNearMainnet.modules = [
      setupMyNearWallet({
        walletUrl: "https://testnet.mynearwallet.com",
        iconUrl: MyNearIconUrl,
      }),
      setupNearWallet({ iconUrl: NearIconUrl }),
    ];
  } else {
    connectedToNearMainnet.modules = [
      setupMyNearWallet({
        walletUrl: "https://app.mynearwallet.com",
        iconUrl: MyNearIconUrl,
      }),
      setupNearWallet({ iconUrl: NearIconUrl }),
      setupSender({ iconUrl: SenderIconUrl }),
    ];
  }
  const walletSelector = await setupWalletSelector({
    network: nearConfig,
    ...connectedToNearMainnet,
  });
  const description = "Please select a wallet to sign in..";
  const contract =
    process.env.REACT_APP_ENV_STAGING === "true"
      ? "genadrop-test.mpadev.testnet"
      : "genadrop-contract.nftgen.near";

  const modal = setupModal(walletSelector, {
    contractId: contract,
    description,
  });
  modal.show();

  const isSignedIn = walletSelector.isSignedIn();
  window.selector = walletSelector;
  const chainId = process.env.REACT_APP_ENV_STAGING === "true" ? 1111 : 1112;

  if (isSignedIn) {
    window.localStorage.setItem("near_wallet", "connected_to_near");
    dispatch(setChainId(chainId));
    dispatch(
      setAccount(walletSelector.store.getState().accounts[0].accountId)
    );
    dispatch(setProposedChain(chainId));
    dispatch(setConnector(walletSelector.wallet()));
  }

  dispatch(setToggleWalletPopup(false));
  // handleProposedChain();

  return;
}
// if (window.selector) {
//   const nearLogout = await window?.selector?.wallet();
//   nearLogout.signOut();
// }

export const connectWallet = async (walletProps) => {
  const {
    dispatch,
    proposedChain,
    connectionMethod,
    walletProviderRef,
    handleSetState,
    mainnet,
    walletConnectProvider,
  } = walletProps;
  if (connectionMethod === "metamask") {
    if (window?.ethereum !== undefined) {
      await WS.connectWithMetamask(walletProps);
      const ethereumProvider = new ethers.providers.Web3Provider(window.ethereum);
      dispatch(setConnector(ethereumProvider));
    } else {
      dispatch(setToggleWalletPopup(false));
      dispatch(
        setNotification({
          message: "You need to install metamask to continue",
          type: "error",
        })
      );
      dispatch(setClipboard("https://metamask.io/"));
    }
  } else if (connectionMethod === "walletConnect") {
    walletProviderRef.current = 2;
    if (proposedChain === 4160) {
      handleSetState({
        rpc: {
          4160: mainnet ? "https://node.algoexplorerapi.io" : "https://node.testnet.algoexplorerapi.io",
        },
      });
    } else {
      handleSetState({
        rpc: {
          [proposedChain]: chainIdToParams[proposedChain].rpcUrls[0],
        },
      });
    }
  } else if (connectionMethod === "magicLink") {
    const magic = new Magic("pk_live_051193EF8469FA65", {
      network: {
        rpcUrl: chainIdToParams[proposedChain].rpcUrls[0],
        chainId: proposedChain,
      },
      locale: "en_US",
      extensions: [new ConnectExtension()],
    });

    const web3 = new Web3(magic.rpcProvider);

    web3.eth
      .getAccounts()
      .then(async (accounts) => {
        // WS.updateAccount(walletProps);

        let res;
        res = await supportedChains[proposedChain]?.switch(proposedChain);
        if (!res) {
          await WS.disconnectWalletConnectProvider(walletConnectProvider);
          const activeChain = await WS.getNetworkID();
          if (activeChain === proposedChain) {
            WS.updateAccount(walletProps);
          }
        } else {
          dispatch(setChainId(Number(proposedChain)));
          dispatch(setAccount(accounts[0]));
          dispatch(
            setNotification({
              message: `Your site is connected to ${supportedChains[proposedChain].label}`,
              type: "success",
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const ethereumProvider = new ethers.providers.Web3Provider(window.ethereum);
    dispatch(setConnector(ethereumProvider));
  }
};

export const disconnectWalletConnectProvider = async (walletConnectProvider) => {
  if (walletConnectProvider?.connected) {
    try {
      await walletConnectProvider.disconnect();
    } catch (error) {
      console.log("error disconneting: ", error);
    }
  }
};

export const updateAccount = async (walletProps) => {
  const { dispatch, walletConnectProvider, mainnet } = walletProps;
  const { ethereum } = window;
  let [accounts] = await ethereum.request({
    method: "eth_accounts", // eth_accounts should not allow metamask to popup on page load //eth_requestAccounts
  });
  const networkId = await ethereum.request({ method: "net_version" });
  await WS.disconnectWalletConnectProvider(walletConnectProvider);
  window.localStorage.removeItem("undefined_wallet_auth_key");
  window.localStorage.removeItem("nearWallet");
  const getEnv = supportedChains[networkId] ? mainnet === supportedChains[networkId].isMainnet : false;
  if (!getEnv) {
    WS.disconnectWallet(walletProps);
    dispatch(setSwitchWalletNotification(true));
    return;
  }
  dispatch(setSwitchWalletNotification(false));
  const isSupported = Object.keys(supportedChains).includes(networkId);
  if (!isSupported) {
    WS.disconnectWallet(walletProps);
    dispatch(setToggleWalletPopup(true));
  } else {
    dispatch(setToggleWalletPopup(false));
    if (!accounts) {
      [accounts] = await ethereum.request({
        method: "eth_requestAccounts",
      });
      if (!accounts) {
        WS.disconnectWallet(walletProps);
        dispatch(
          setNotification({
            message: "Please connect your site manually from your wallet extension.",
            type: "warning",
          })
        );
      }
    } else {
      dispatch(setChainId(Number(networkId)));
      dispatch(setAccount(accounts));
      dispatch(
        setNotification({
          message: `Your site is connected to ${supportedChains[networkId].label}`,
          type: "success",
        })
      );
    }
  }
};

export const disconnectWallet = async ({ walletConnectProvider, dispatch, history, pathname, handleSetState }) => {
  await WS.disconnectWalletConnectProvider(walletConnectProvider);
  dispatch(setProposedChain(null));
  dispatch(setChainId(null));
  if (localStorage.getItem("near_wallet") === "connected_to_near") {
    const nearLogout = await window?.selector?.wallet();
    nearLogout.signOut();
    window.localStorage.removeItem("near_wallet");
  }
  if (window?.near?.isSignedIn()) {
    window?.near?.signOut();
  }
  handleSetState({ toggleDropdown: false });
  if (window.localStorage.undefined_wallet_auth_key || window.localStorage.nearConnection) {
    window.localStorage.removeItem("undefined_wallet_auth_key");
    window.localStorage.removeItem("nearConnection");
  }
  if (pathname.includes("/profile")) {
    history.push("/marketplace");
  }
  dispatch(setAccount(null));
};
