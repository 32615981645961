import React from "react";
import { ReactComponent as Collection } from "../../assets/create/collection-icon.svg";
import { ReactComponent as Vibes } from "../../assets/create/proofofvibes-icon.svg";
import { ReactComponent as Photo } from "../../assets/create/photo-icon.svg";
import { ReactComponent as Sesh } from "../../assets/create/sesh-icon.svg";
import { ReactComponent as Shorts } from "../../assets/create/shorts-icon.svg";
import { ReactComponent as Art } from "../../assets/create/art-icon.svg";
import { ReactComponent as Doubletake } from "../../assets/create/bereal-icon.svg";
import { ReactComponent as Maps } from "../../assets/create/maps.svg";

import { ReactComponent as Ai } from "../../assets/create/ai-icon.svg";
import { ReactComponent as Audio } from "../../assets/create/MusicNotes.svg";
import { ReactComponent as Video } from "../../assets/create/MintVideos.svg";
import { ReactComponent as Tweets } from "../../assets/create/tweets-icon.svg";
// import { ReactComponent as Ipfs } from "../../assets/create/ipfsIcon.svg";

const cards = [
  // {
  //   title: "Collection",
  //   description: "Import arts into layers",
  //   icon: <Collection />,
  //   url: "/collection",
  //   comingSoon: "",
  // },
 
 
  // {
  //   title: "Shorts",
  //   description: "Take short video and mint",
  //   icon: <Shorts />,
  //   url: "/mint/video",
  //   comingSoon: "",
  // },
  {
    title: "Proof Of Vibes",
    description: "Mint your favorite moments on chain.",
    icon: <Vibes />,
    url: "/mint/vibe",
    comingSoon: "",
  },
  // {
  //   title: "Proof Of Sesh",
  //   description: "Scan your stick for proof",
  //   icon: <Sesh />,
  //   url: "/mint/sesh",
  //   comingSoon: "",
  // },
  
 
];
export default cards;
