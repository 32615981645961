import { useContext, useEffect } from "react";
import { ethers } from "ethers";
import { fetchAlgoCollections, fetchAlgoSingle } from "../../utils/firebase";
import {
  setCollections,
  setSingleNfts,
  setAlgoCollections,
  setAlgoSingleNfts,
  setAuroraCollections,
  setPolygonCollections,
  setAuroraSingleNfts,
  setPolygonSingleNfts,
  setNotification,
  setCeloCollections,
  setCeloSingleNft,
  setSearchContainer,
  setNearSingleNft,
  setAvaxSingleNfts,
  setArbitrumNfts,
  setAllNfts,
} from "../../gen-state/gen.actions";
import {
  getGraphCollections,
  getNftCollections,
  getSingleNfts,
  getSingleGraphNfts,
  fetchNearSingleNfts,
} from "../../utils";
import {
  GET_GRAPH_COLLECTIONS,
  GET_ALL_POLYGON_COLLECTIONS,
  GET_AURORA_SINGLE_NFTS,
  GET_POLYGON_SINGLE_NFTS,
  GET_CELO_SINGLE_NFT,
  GET_CELO_GRAPH_COLLECITONS,
  GET_NEAR_SINGLE_NFTS,
  GET_POLYGON_SOUL_BOUND_NFTS,
  GET_CELO_SOUL_BOUND_NFTS,
} from "../../graphql/querries/getCollections";
import {
  arbitrumClient,
  auroraClient,
  avalancheClient,
  celoClient,
  nearClient,
  polygonClient,
} from "../../utils/graphqlClient";
import { GenContext } from "../../gen-state/gen.context";
import {
  parseAlgoCollection,
  parseAlgoSingle,
  parseArbitrumSingle,
  parseAuroraCollection,
  parseAuroraSingle,
  parseAvaxSingle,
  parseCeloCollection,
  parseCeloSingle,
  parseNearSingle,
  parsePolygonCollection,
  parsePolygonSingle,
} from "./fetchData-script";

const FetchData = () => {
  const { dispatch, mainnet } = useContext(GenContext);
  useEffect(() => {
    // Near Single Nfts
    (async function getNearSingleNfts() {
      const { data, error } = await nearClient
        .query(GET_NEAR_SINGLE_NFTS)
        .toPromise();
      if (error) {
        return dispatch(
          setNotification({
            message: error.message,
            type: "warning",
          })
        );
      }
      const result = await fetchNearSingleNfts(data?.nfts);
      if (result) {
        dispatch(setNearSingleNft(result));
        dispatch(
          setSearchContainer({
            "Near 1of1": parseNearSingle(result),
          })
        );
      } else {
        dispatch(setNearSingleNft(null));
      }
    })();
  }, [mainnet]);

  return null;
};

export default FetchData;
